<template>
	<div>
		<el-form :inline="true" :model="params" size="mini">
			<el-form-item>
				<el-input v-model="params.keywords" placeholder="编码"></el-input>
			</el-form-item>
			<el-form-item  v-if="!admin_user.shop_id">
				<el-select v-model="params.shop_id" placeholder="门店" filterable clearable>
					<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-select v-model="params.type" placeholder="类型" filterable clearable>
					<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-select v-model="params.merchant_type" placeholder="平台" filterable clearable>
					<el-option label="站内" value=" "></el-option>
					<el-option v-for="(merchant_type, t) in merchant_types" :key="t" :label="merchant_type" :value="t"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getCoupons({ ...params, page: 1})">查询</el-button>
				<el-button type="primary" @click="getCoupons(params)">刷新</el-button>
				<el-button type="primary" @click="getCoupons({})">重置</el-button>
				<el-button type="primary" @click="create_drawer = true" :disabled="!$utils.checkAuth('coupons', 'create')">创建优惠券</el-button>
			</el-form-item>
		</el-form>

		<el-table ref="coupons_table" :data="coupons" :max-height="theme.height - 250" :size="theme.size">
			<el-table-column prop="no" label="基本信息">
				<template slot-scope="scope">
					<p>No.{{scope.row.no}}</p>
					<p>由 {{scope.row.shop.name}}@{{scope.row.user.name}} 创建 《{{scope.row.title}}》</p>
				</template>
			</el-table-column>
			<el-table-column prop="use_rule" label="核销规则" width="420">
				<template slot-scope="scope">
					<p>消费门槛：满 {{scope.row.transaction_minimum/100}} 元，{{scope.row.type == 'DISCOUNT'?('打 '+(scope.row.discount_amount/10)+' 折'):('立减 '+(scope.row.discount_amount/100)+' 元')}}</p>
					<p>使用时间：{{scope.row.available_begin_at}} 至 {{scope.row.available_end_at}}</p>
					<p>
						Tips:
						<span v-if="scope.row.wait_days_after_receive>0"> 领取后{{scope.row.wait_days_after_receive}}天开始生效，</span>
						<span v-else> 领取后立即生效，</span>
						<span v-if="scope.row.available_day_after_receive"> 生效后{{scope.row.available_day_after_receive}}天内有效(必须在活动时间内)</span>
						<span v-else> 活动时间结束内有效</span>
					</p>
				</template>
			</el-table-column>
			<el-table-column prop="send_rule" label="发放规则">
				<template slot-scope="scope">
					<p>发行/已发行：{{scope.row.max_coupons}} / {{scope.row.user_coupons_count}}</p>
					<p>{{users[scope.row.user]}}当天/累计可领取：{{scope.row.max_coupons_by_day}} / {{scope.row.max_coupons_per_user}}</p>
				</template>
			</el-table-column>
			<el-table-column prop="extra_rule" label="额外规则">
				<template slot-scope="scope">
					<template v-if="scope.row.merchant">
						<p v-if="scope.row.stock_id">No.{{scope.row.stock_id}}</p>
						<p>同步于 《{{scope.row.merchant.name}}》 {{merchant_types[scope.row.merchant_type]}} 【{{methods[scope.row.extra_rule.use_method]}}】 核销</p>
						<p>仅限于 {{scope.row.extra_rule.goods_name}}</p>
					</template>
					<p v-else>此券为站内券无额外规则</p>
				</template>
			</el-table-column>
			<el-table-column prop="at" label="操作时间" width="210">
				<template slot-scope="scope">
					<template v-if="scope.row.deleted_at">删除：{{scope.row.deleted_at}}</template>
					<template v-else>更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
				</template>
			</el-table-column>
			<el-table-column prop="name" label="操作" width="100">
				<template slot-scope="scope">
					<el-button type="text" @click="onDelete(scope.row)" v-if="!scope.row.deleted_at">删除</el-button>
					<el-button type="text" :disabled="!$utils.checkAuth('coupons/records', 'default')" @click="show_coupon = {...scope.row}; show_drawer = true; getCouponRecords({...c_params, coupon_id: scope.row.id})">记录</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getCoupons({ ...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getCoupons({ ...params, page: v}) }"
			background>
		</el-pagination>

		<el-drawer title="创建优惠券" size="740px" :visible.sync="create_drawer" @closed="resetForm('create_coupon')">
			<div style="padding: 0 15px;">
				<el-form ref="create_coupon" label-width="120px" :model="coupon" :rules="coupon_rules" :size="theme.size">
					<el-form-item label="卡券标题" prop="title">
						<el-input v-model="coupon.title" placeholder="请输入卡券标题。示例值：8月1日活动券" maxlength="21" show-word-limit>
							<el-select v-model="coupon.merchant_type" slot="prepend" style="width: 120px;" placeholder="请选择">
								<el-option label="站内券" value=""></el-option>
								<el-option v-for="(type, t) in merchant_types" :key="t" :label="type" :value="t"></el-option>
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item label="卡券类型" prop="discount_amount">
						<el-input placeholder="请输入优惠额度" maxlength="5" v-model.number="coupon.discount_amount">
							<el-select v-model="coupon.type" slot="prepend" style="width: 120px;" placeholder="请选择">
								<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
							</el-select>
							<template slot="append" v-if="coupon.type == 'DISCOUNT'">%</template>
							<template slot="append" v-else>分</template>
						</el-input>
					</el-form-item>
					<el-form-item label="消费门槛" prop="transaction_minimum">
						<el-input placeholder="请输入消费门槛" maxlength="10" v-model.number="coupon.transaction_minimum" clearable>
							<template slot="append">分</template>
						</el-input>
					</el-form-item>
					<el-form-item label="使用时间" prop="use_at">
						<el-date-picker v-model="coupon.use_at" style="width: 100%;" type="datetimerange" value-format="yyyy-MM-dd 00:00:00" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item label="开始生效" prop="wait_days_after_receive">
						<el-input placeholder="请输入领取后N天开始生效。默认为0立即生效" maxlength="10" v-model.number="coupon.wait_days_after_receive">
							<template slot="append">天</template>
						</el-input>
					</el-form-item>
					<el-form-item label="生效后有效" prop="available_day_after_receive" :rules="[
						{ required: coupon.wait_days_after_receive > 0 ? true : false, message: '请输入生效后N天内有效', trigger: 'blur' },
						{ type: 'number', message: '最小为1天', min: 1, trigger: 'blur' },
						{ type: 'number', message: '最大不能超过30天', max: 30, trigger: 'blur' }
					]">
						<el-input placeholder="请输入生效后N天内有效。" maxlength="10" v-model.number="coupon.available_day_after_receive" clearable>
							<template slot="append">天</template>
						</el-input>
					</el-form-item>
					<el-form-item label="用户类型" prop="user">
						<el-radio-group v-model="coupon.user">
							<el-radio label="all">新老用户</el-radio>
							<el-radio label="new">新用户</el-radio>
							<el-radio label="old">老用户</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="发放个数" prop="max_coupons">
						<el-input placeholder="请输入最大可发放个数限制。" maxlength="10" v-model.number="coupon.max_coupons" clearable>
							<template slot="append">张</template>
						</el-input>
					</el-form-item>
					<el-form-item label="可领个数" prop="max_coupons_per_user">
						<el-input placeholder="请输入用户可领个数，最多100张券。" maxlength="10" v-model.number="coupon.max_coupons_per_user" clearable>
							<template slot="append">张</template>
						</el-input>
					</el-form-item>
					<el-form-item label="当天可领" prop="max_coupons_by_day">
						<el-input placeholder="请输入用户当天可领个数。取值范围 1 ≤ N ≤ 1000000000" maxlength="10" v-model.number="coupon.max_coupons_by_day" clearable>
							<template slot="append">张</template>
						</el-input>
					</el-form-item>
					<template v-if="!coupon.merchant_type">
						<el-form-item label="使用范围" prop="extra_rule.type">
							<el-select placeholder="请选择使用范围" v-model="coupon.extra_rule.type" @change="changeType">
								<el-option label="类目" value="categories"></el-option>
								<el-option label="品牌" value="brands"></el-option>
								<el-option label="单品" value="goods"></el-option>
								<el-option label="标签" value="tags"></el-option>
							</el-select>
							<el-transfer style="margin-top: 10px;" v-model="coupon.extra_rule.available" :data="transfers" :titles="['不可用', '可用']" :props="{ key: 'id', label: 'name' }" :filter-method="filterMethod" @change="changeTransfer" v-if="coupon.extra_rule.type" filterable>
								<template slot-scope="{ option }">
									<template v-if="coupon.extra_rule.type === 'goods'">{{ option.number }}-{{ option.name }}</template>
									<template v-else>{{ option.name }}-{{ option.goods_count }}</template>
								</template>
								<template  slot="left-footer"><el-checkbox v-model="coupon.extra_rule.childrens" :disabled="coupon.extra_rule.type !== 'categories'">递归子类目</el-checkbox></template>
								<el-checkbox slot="right-footer" v-model="coupon.extra_rule.superposition">可叠加优惠券</el-checkbox>
							</el-transfer>
						</el-form-item>
					</template>
					<template v-if="coupon.merchant_type == 'App\\Models\\WechatMerchant'">
						<el-form-item label="商户号" prop="merchant_id">
							<el-select placeholder="请选择商户号" v-model="coupon.merchant_id">
								<el-option v-for="merchant in merchants" :key="merchant" :label="merchant" :value="merchant"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="核销方式" prop="extra_rule.use_method">
							<el-select placeholder="请选择核销方式" v-model="coupon.extra_rule.use_method">
								<el-option v-for="(method, m) in methods" :key="m" :label="method" :value="m"></el-option>
							</el-select>
						</el-form-item>
						<template v-if="coupon.extra_rule.use_method == 'MINI_PROGRAMS'">
							<el-form-item label="小程序appid" prop="extra_rule.mini_programs_appid">
								<el-input v-model="coupon.extra_rule.mini_programs_appid" placeholder="核销方式为线上小程序核销才有效。" maxlength="32" show-word-limit></el-input>
							</el-form-item>
							<el-form-item label="小程序path" prop="extra_rule.mini_programs_path">
								<el-input v-model="coupon.extra_rule.mini_programs_path" placeholder="核销方式为线上小程序核销才有效。" maxlength="128" show-word-limit></el-input>
							</el-form-item>
						</template>
						<el-form-item label="商品描述" prop="extra_rule.goods_name">
							<el-input v-model="coupon.extra_rule.goods_name" placeholder="描述哪些商品可用，会显示在微信卡包中。示例值：xxx商品使用" maxlength="15" show-word-limit></el-input>
						</el-form-item>
						<el-form-item label="备注说明">
							<el-input v-model="coupon.extra_rule.comment" placeholder="仅配置商户可见，用于自定义信息。示例值：活动使用" maxlength="20" show-word-limit></el-input>
						</el-form-item>
						<el-form-item label="LOGO" prop="extra_rule.logo">
							<el-upload class="coupon-uploader" :action="$config.APIHOST+'uploads'" :show-file-list="false">
								<img v-if="coupon.logo" :src="coupon.extra_rule.logo" style="width: 60px; height: 60px; display: block;">
								<i v-else class="el-icon-plus" style="width: 60px; height: 60px; color: #8c939d; font-size: 28px; line-height: 60px; text-align: center;"></i>
								<div slot="tip" class="el-upload__tip">支持JPG/JPEG/PNG格式、大小需为120*120且小于1M。<br><a href="https://pay.weixin.qq.com/wiki/doc/apiv3/assets/img/common/marketing/chapter3_1_5.png" target="_blank">点击查看微信卡券样式</a></div>
							</el-upload>
						</el-form-item>
						<el-form-item label="公众号" prop="extra_rule.name">
							<el-input v-model="coupon.extra_rule.name" placeholder="可从券详情可跳转至商户号有绑定关系的公众号APPID" maxlength="32" show-word-limit></el-input>
						</el-form-item>
						<el-form-item label="背景颜色" prop="wechat_color">
							<el-select v-model="coupon.extra_rule.wechat_color" placeholder="请选择券的背景颜色，可设置10种颜色">
								<el-option v-for="(color, c) in colors" :key="c" :label="color" :value="c">
									<span :style="'width: 15px; height: 15px; display: inline-block; margin-right: 5px; background-color: '+color"></span>{{ color }}
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="使用须知" prop="extra_rule.wechat_description">
							<el-input type="textarea" rows="3" v-model="coupon.extra_rule.wechat_description" placeholder="用于说明详细的活动规则，会展示在代金券详情页。示例值：xxx门店可用" maxlength="1000" show-word-limit></el-input>
						</el-form-item>
					</template>
					<div style="text-align: right; margin-top: 20px; padding-bottom: 20px;">
						<el-button type="primary" @click="submitForm('create_coupon')">立即创建</el-button>
						<el-button @click="resetForm('create_coupon')">取消</el-button>
					</div>
				</el-form>
			</div>
		</el-drawer>

		<el-drawer title="优惠券记录" size="550px" :visible.sync="show_drawer">
			<div style="padding: 0 10px 10px; line-height: 1.5;" v-if="show_coupon">
				<h3>No.{{show_coupon.no}}</h3>
				<p>由 {{show_coupon.user.name}} 创建 《{{show_coupon.title}}》</p>
				<p>{{users[show_coupon.user]}} 满 {{show_coupon.transaction_minimum/100}} 元，{{show_coupon.type == 'DISCOUNT'?('打 '+(show_coupon.discount_amount/10)+' 折'):('立减 '+(show_coupon.discount_amount/100)+' 元')}}</p>
				<p>使用时间：{{show_coupon.available_begin_at}} 至 {{show_coupon.available_end_at}}</p>
			</div>
			<template v-if="coupon_users.length">
				<el-collapse accordion>
					<el-collapse-item v-for="coupon_user in coupon_users" :key="coupon_user.id">
						<div slot="title" style="display: flex; line-height: 1.5; align-items: center; justify-content: space-between; width: 100%; padding: 0 10px;">
							<div>
								<div>{{coupon_user.created_at}} {{coupon_user.user.name}} 领取</div>
								<div>{{coupon_user.created_at}} {{coupon_user.status_name}} {{coupon_user.hidden_code}}</div>
							</div>
							<el-button type="text" icon="el-icon-delete" @click.stop.prevent="handleAction({type: 'delete', coupon: coupon_user})"></el-button>
						</div>
						<div style="padding: 10px 20px 0;">
							<el-input type="textarea" placeholder="请输入操作备注说明。" v-model="coupon_user.reason" maxlength="128" show-word-limit></el-input>
							<el-button-group style="padding: 10px 0;">
								<el-button type="primary" icon="el-icon-edit" :size="theme.size" :disabled="coupon_user.status != 'SENDED'" @click="handleAction({type: 'use', coupon: coupon_user})">核销</el-button>
								<el-button type="primary" icon="el-icon-share" :size="theme.size" :disabled="coupon_user.status != 'USED'" @click="handleAction({type: 'return', coupon: coupon_user})">退券</el-button>
								<el-button type="primary" icon="el-icon-delete" :size="theme.size" :disabled="coupon_user.status != 'SENDED'" @click="handleAction({type: 'deactivate', coupon: coupon_user})">失效</el-button>
							</el-button-group>
							<el-timeline>
								<el-timeline-item v-for="(record, index) in coupon_user.user_coupon_operates" :key="index" :color="record.color" :timestamp="record.created_at">{{record.content}}</el-timeline-item>
							</el-timeline>
						</div>
					</el-collapse-item>
				</el-collapse>
				<el-pagination
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="c_params.perPage"
					:current-page="c_params.page"
					:total="c_params.total"
					@size-change="(v) => { getCouponRecords({ ...this.c_params, page: 1, perPage: v}) }"
					@current-change="(v) => { getCouponRecords({ ...this.c_params, page: v}) }"
					v-if="c_params.total > c_params.perPage"
					background>
				</el-pagination>
			</template>
			<el-divider v-else>暂无领取记录</el-divider>
		</el-drawer>
	</div>
</template>

<style>
	.coupon-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.coupon-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.coupon_card {
		color: #fff;
		overflow: hidden;
		line-height: 1.5;
		position: relative;
		padding: 10px 20px;
		margin-bottom: 20px;
	}
	.coupon_card .status {
		width: 100px;
		height: 100px;
		font-size: 24px;
		line-height: 100px;
		border-radius: 50px;
		transform: rotate(25deg);
		color: rgba(255, 255, 255, 0.8);
		background-color: rgba(255, 255, 255, 0.2);
		border: 2px dashed rgba(255, 255, 255, 0.5);
		text-align: center;
		position: absolute;
		top: -15px;
		right: -15px;
	}
</style>

<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			handleAction (a) {
				const r = a.coupon;
				switch (a.type) {
					case 'use':
						r.type = 'use';
						this.$confirm(r.reason, '确定要核销【'+r.coupon.title+'】优惠券吗？', {
							confirmButtonText: '核销',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							this.submitCouponRecord(r);
						});
					break;
					case 'return':
						r.type = 'return';
						this.$confirm(r.reason, '确定要退券【'+r.coupon.title+'】优惠券吗？', {
							confirmButtonText: '退券',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							this.submitCouponRecord(r);
						});
					break;
					case 'deactivate':
						r.type = 'deactivate';
						this.$confirm(r.reason, '确定要【'+r.coupon.title+'】优惠券失效吗？', {
							confirmButtonText: '失效',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							this.submitCouponRecord(r);
						});
					break;
					case 'delete':
						this.$confirm('确定要删除【'+r.coupon.title+'】优惠券吗？', '提示', {
							confirmButtonText: '删除',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.get(this.$api.URI_COUPONS_RECORDS, {params: {action: 'destroy', id: r.id}, headers: {loading: true}});
							const { code, msg } = res.data;
							if (code != 0) return this.$message.error(msg);
							this.getCouponRecords(this.c_params);
							this.$message.success(msg);
						});
					break;
				}
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.title+'】优惠券吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_COUPONS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getCoupons(this.params);
					this.$message.success(msg);
				})
			},
			async submitCouponRecord (data) {
				const res = await this.$http.post(this.$api.URI_COUPONS_RECORDS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getCouponRecords(this.c_params);
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					if (!this.coupon.merchant_type && (!this.coupon.extra_rule.available || this.coupon.extra_rule.available.length < 1))  return this.$message.error("当前为站内券，必须提交使用范围！");
					this.submitCoupon(this.coupon);
				})
			},
			resetForm (ref) {
				this.create_drawer = false;
				this.$refs[ref].resetFields();
				this.coupon = {
					type: 'NORMAL',
					merchant_type: '',
					use_rule: {
						wait_days_after_receive: 0
					},
					send_rule: {
						user: 'all'
					},
					extra_rule: {}
				};
			},
			async submitCoupon (data) {
				const res = await this.$http.post(this.$api.URI_COUPONS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.create_drawer) {
							this.create_drawer = false;
							this.getCoupons(this.params);
						}
					}
				})
			},
			async changeType (e) {
				const res = await this.$http.get(this.$api.URI_COUPONS, {params: {action: 'available', table: e}});
				this.transfers = res.data.result;
				this.coupon.extra_rule = {
					type: e,
					childrens: false,
					superposition: false,
					available: []
				}
			},
			changeTransfer (e) {
				if (this.coupon.extra_rule.childrens) {
					this.coupon.extra_rule = { ...this.coupon.extra_rule, available: this.recursion(e, []) }
				}
			},
			recursion (e, d = []) {
				e.map((i) => {
					this.transfers.map((t) => {
						if (t.id == i) {
							!d.includes(t.id) && d.push(t.id);
							if (t.childrens.length > 0) {
								return this.recursion(t.childrens.map((c) => { return c.id }), d);
							}
						}
					});
				});
				return d;
			},
			async getCoupons (p) {
				const res = await this.$http.get(this.$api.URI_COUPONS, {params: p});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.coupons = result.data;
				this.shops = result.shops;
				this.types = result.types;
				this.methods = result.methods;
				this.merchants = result.merchants;
				this.merchant_types = result.merchant_types;
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async getCouponRecords (p) {
				const res = await this.$http.get(this.$api.URI_COUPONS_RECORDS, {params: p});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.coupon_users = result.data;
				this.statuses = result.status;
				this.c_params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data () {
			return {
				transfers: [],
				coupons: [],
				types: [],
				shops: [],
				methods: [],
				statuses: [],
				merchants: [],
				merchant_types: [],
				coupon_users: [],
				filterMethod(query, item) {
					if (item.number) {
						return item.number.indexOf(query) > -1 || item.name.indexOf(query) > -1;
					}
					return item.name.indexOf(query) > -1;
				},
				users: {
					'all': '新老用户',
					'new': '新用户',
					'old': '老用户',
				},
				colors: {
					'Color010': '#63B359',
					'Color020': '#2C9F67',
					'Color030': '#509FC9',
					'Color040': '#5885CF',
					'Color050': '#9062C0',
					'Color060': '#D09A45',
					'Color070': '#E4B138',
					'Color080': '#EE903C',
					'Color090': '#DD6549',
					'Color100': '#CC463D',
				},
				create_drawer: false,
				show_drawer: false,
				show_coupon: null,
				coupon: {
					type: 'NORMAL',
					merchant_type: '',
					wait_days_after_receive: 0,
					user: 'all',
					extra_rule: {}
				},
				params: {
					perPage: 10,
					type: []
				},
				c_params: {
					perPage: 10,
					type: []
				},
				coupon_rules: {
					title: [
						{ required: true, message: '请输入优惠券名称', trigger: 'blur' }
					],
					discount_amount: [
						{ required: true, message: '请输入优惠券优惠券', trigger: 'blur' },
						{ type: 'number', message: '最小为1分', min: 1, trigger: 'blur' },
					],
					use_at: [
						{ required: true, type: 'array', message: '请选择使用时间', trigger: 'change' }
					],
					transaction_minimum: [
						{ required: true, message: '请输入优惠券消费门槛', trigger: 'blur' },
						{ type: 'number', message: '最小为1分', min: 1, trigger: 'blur' },
						{ type: 'number', message: '最大不能超过30000分', max: 30000, trigger: 'blur' }
					],
					wait_days_after_receive: [
						{ required: true, message: '请输入领取后N天开始生效。最小为0天立即生效', trigger: 'blur' },
						{ type: 'number', message: '最小为0天', min: 0, trigger: 'blur' },
						{ type: 'number', message: '最大不能超过30天', max: 30, trigger: 'blur' }
					],
					user: [
						{ required: true, message: '请选择用户类型', trigger: 'change' }
					],
					max_coupons: [
						{ required: true, message: '请输入本次发放个数', trigger: 'blur' },
						{ type: 'number', message: '最小为1张', min: 1, trigger: 'blur' },
						{ type: 'number', message: '最大不能超过30000张', max: 30000, trigger: 'blur' }
					],
					max_coupons_per_user: [
						{ required: true, message: '请输入用户可领个数，最多100张券', trigger: 'blur' },
						{ type: 'number', message: '最小为1张', min: 1, trigger: 'blur' },
						{ type: 'number', message: '最大不能超过100张', max: 100, trigger: 'blur' }
					],
					max_coupons_by_day: [
						{ required: true, message: '请输入用户当天可领个数，取值范围 1 ≤ N ≤ 1000000000', trigger: 'blur' },
						{ type: 'number', message: '最小为1张', min: 1, trigger: 'blur' },
						{ type: 'number', message: '最大不能超过1000000000张', max: 1000000000, trigger: 'blur' }
					],
					merchant_id: [
						{ required: true, message: '请选择商家商户号', trigger: 'change' }
					],
					'extra_rule.type': [
						{ required: true, message: '请选择使用范围', trigger: 'change' }
					],
					'extra_rule.use_method': [
						{ required: true, message: '请选择核销方式', trigger: 'change' }
					],
					'extra_rule.mini_programs_appid': [
						{ required: true, message: '核销方式为线上小程序核销必须填写', trigger: 'change' }
					],
					'extra_rule.mini_programs_path': [
						{ required: true, message: '核销方式为线上小程序核销必须填写', trigger: 'change' }
					],
					'extra_rule.goods_name': [
						{ required: true, message: '请输入商品描述', trigger: 'blur' }
					],
				}
			}
		},
		async created () {
			this.getCoupons(this.params)
		}
	};
</script>